import React, { useState, useEffect } from 'react';
import emptySearch from './../../../images/empty_search.png';
import { DocRequestData } from '../../../types/docRequestModel';
import Searchbar from '../../layouts/Searchbar';
import io from 'socket.io-client';
import RequestItem from '../../layouts/RequestItem';
import Modal from "../../layouts/Modal";
import strings from '../../../assets/strings';
import CustomPatientModalWithScroll from "../../layouts/CustomPatientModalWithScroll";
import { BooleanFiltersData } from '../../../types/booleanFilters';
import PriorityTag from '../../layouts/PriorityTag';

import SeatCounter from '../../layouts/SeatCounter';

// Utils imports
import { getTheRightRequestType } from '../../../utils/AppointementRequestDecode';
import { getTheClosestAvailableDates, convertToISO8601Date, getFormalDateWithDayAsNumberShortcutMonthAndYear, calculateAge } from '../../../utils/IAHelperForDate';
// Icons imports
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AppointementDashboardViewModel from './AppointementDashboardViewModel';
import SnippetFolderRoundedIcon from '@mui/icons-material/SnippetFolderRounded';
// Views imports
import DeleteAppointementView from '../../layouts/DeleteAppointementView';
import AcceptAppointementView from '../../layouts/AcceptAppointementView';
import { ModalTypeEnum } from '../../../types/ModalTypeEnum';
import FilterRequestView from '../../layouts/FilterRequestView';
import ErrorView from '../../layouts/ErrorView';
import AssignRequestView from '../../layouts/AssignRequestView';
import ApiService from '../../../services/ApiService';
import AppointmentRepository from '../../../Domain/Appointments/AppointmentRepository';
import { GeneralAppointementResponse } from '../../../types/GeneralAppointementResponse';
import LayoutHeader from '../../Headers/LayoutHeader';
import { generateAssignmentEmailHtml } from '../../mailSender/EmailTemplate';

interface AppointementDashboardViewProps {
    userId: number;
    appointementDashboardViewModel: AppointementDashboardViewModel;
}

const AppointementDashboardView: React.FC<AppointementDashboardViewProps> = ({ userId, appointementDashboardViewModel }) => {
    const socket = io("https://api.doctraitant.fr");
    const apiUrl = "https://api.doctraitant.fr/api";
    const apiService = new ApiService(apiUrl);
    const appointmentRepository = new AppointmentRepository(localStorage.getItem("userId") ?? "", apiService);
    const [data, setData] = useState<DocRequestData[]>([]);
    const [zipcode, setZipcode] = useState<string[]>([]);
    const [emails, setEmails] = useState<string[] | undefined>(undefined);
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isResetOrSelectStatusFilters, setIsResetOrSelectStatusFilters] = useState(false);

    //Appointement ask Management
    const [initModal, setInitModal] = useState(false);
    const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.Nothing);

    const [selectedAppointement, setSelectedAppointement] = useState<DocRequestData>();
    const [schedulingModal, setSchedulingModal] = useState(false);

    const [appointementDataShowModal, setAppointementDataShowModal] = useState(false)
    const [schedulingForm, setSchedulingForm] = useState<number>(0)
    const [dateTimeValue, setDateTimeValue] = useState<string>('');
    const [datesSuggestions, setDatesSuggestions] = useState<Date[]>([]);
    const [emailValue, setEmailValue] = useState<string | null>(localStorage.getItem("email"));
    const [phoneValue, setPhoneValue] = useState<string | null>(localStorage.getItem("phone"));
    const [adresseValue, setAdresseValue] = useState<string | null>(localStorage.getItem("address"));
    const [cityValue, setCityValue] = useState<string | null>(localStorage.getItem("city"));
    const [communeValue, setCommuneValue] = useState<string | null>(localStorage.getItem("township"));
    const [zipcodeValue, setZipcodeValue] = useState<string | null>(localStorage.getItem("zipcode"));

    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValue(event.target.value);
    };

    const handleCommuneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommuneValue(event.target.value);
    };

    const handleZipcodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipcodeValue(event.target.value);
    };

    const handleCityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleAdresseInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdresseValue(event.target.value);
    };

    // Asking for actions modal
    const [actionModalOpened, setActionModalOpened] = useState(false);

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneValue(event.target.value);
    };
    const handleDateTimeChange = (value: string) => {
        setDateTimeValue(value);
    };
    const closeAppointementDataShowModal = () => {
        setInitModal(true);
        setAppointementDataShowModal(false);
    }

    const resetSchedulingModalFields = () => {
        setAdresseValue(localStorage.getItem("address") ?? "N/A");
        setCityValue(localStorage.getItem("city") ?? "N/A");
        setCommuneValue(localStorage.getItem("township") ?? "N/A");
        setEmailValue(localStorage.getItem("email"));
        setPhoneValue(localStorage.getItem("phone"));
        setZipcodeValue(localStorage.getItem("zipcode"));
        setDateTimeValue('');
    }

    const closeSchedulingModal = () => {
        setModalType(ModalTypeEnum.Nothing);
        setActionModalOpened(false);
        setSelectedAppointement(undefined);
        setSchedulingForm(0);
        resetSchedulingModalFields();
    }

    const schedulePreviousForm = () => {
        setSchedulingForm(prevSchedulingForm => prevSchedulingForm - 1);
    }

    const selectSuggestedDate = (selectedItem: Date) => {
        setDateTimeValue(convertToISO8601Date(selectedItem))
    }

    const scheduleAppointement = async () => {
        const incrementForm = () => {
            setSchedulingForm(prevSchedulingForm => prevSchedulingForm + 1);
        };

        if (schedulingForm < 2) {
            incrementForm();
            return;
        }

        if (schedulingForm === 2) {
            setDataLoading(true);
            try {
                const response = await appointmentRepository.updateAppointment(selectedAppointement?._id ?? "", {
                    meetingDateTime: dateTimeValue,
                    requestState: "Acceptée",
                    meeting: {
                        doctorID: localStorage.getItem("userId"),
                        doctorUUID: localStorage.getItem("userUUID"),
                        doctorFirstname: localStorage.getItem("firstname"),
                        doctorLastname: localStorage.getItem("lastname"),
                        doctorAddress: adresseValue,
                        doctorAddressCity: cityValue,
                        doctorAddressZipcode: zipcodeValue,
                        doctorEmail: emailValue,
                        doctorPhone: phoneValue,
                        meetingDateTime: dateTimeValue,
                    },
                })
                console.log('Mise à jour de la demande réussie');
                setSchedulingForm(3);
            } catch (error) {
                setModalType(ModalTypeEnum.Error);
                setDataLoading(false);
                setActionModalOpened(true);
                console.error('Erreur lors de la mise à jour de la demande :', error);
            }
        }
    };

    // Filters
    const [selectedTypeItems, setSelectedTypeItems] = useState<BooleanFiltersData[]>(() => {
        const storedTypeItems = localStorage.getItem('selectedTypeItems');
        return storedTypeItems ? JSON.parse(storedTypeItems) : [
            {
                label: "Médecine traitante",
                shortlabel: "MT",
                value: true
            },
            {
                label: "Consultation",
                shortlabel: "Consult",
                value: true
            }
        ];
    });

    // Request State Filter
    const [selectedStateItems, setSelectedStateItems] = useState<BooleanFiltersData[]>(() => {
        const storedStateItems = localStorage.getItem('selectedStateItems');
        return storedStateItems ? JSON.parse(storedStateItems) : [
            {
                label: "Urgence",
                value: true
            },
            {
                label: "Moyenne",
                value: true
            },
            {
                label: "Faible",
                value: true
            }
        ];
    });

    // Request Situation Filter
    const [selectedSituationItems, setSelectedSituationItems] = useState<BooleanFiltersData[]>(() => {
        const storedSituationItems = localStorage.getItem('selectedSituationItems');
        return storedSituationItems ? JSON.parse(storedSituationItems) : [
            {
                label: "Enceinte",
                value: true
            },
            {
                label: "Handicap",
                value: true
            },
            {
                label: "Mobile",
                value: true
            },
            {
                label: "Affection Longue Durée (ALD)",
                value: true
            }
        ];
    });

    const handleTypeSelectionChange = (newSelection: BooleanFiltersData[]) => {
        setSelectedTypeItems(newSelection);
        localStorage.setItem('selectedTypeItems', JSON.stringify(newSelection));
    };

    const handleStateSelectionChange = (newSelection: BooleanFiltersData[]) => {
        setSelectedStateItems(newSelection);
        localStorage.setItem('selectedStateItems', JSON.stringify(newSelection));
    };

    const handleSituationSelectionChange = (newSelection: BooleanFiltersData[]) => {
        setSelectedSituationItems(newSelection);
        localStorage.setItem('selectedSituationItems', JSON.stringify(newSelection));
    };

    const initFilterTriggerAction = () => {
        if (getCurrentEnabledFilter() > 0) {
            setIsResetOrSelectStatusFilters(true)
        } else {
            setIsResetOrSelectStatusFilters(false)
        }
    }

    const handleDataDisplayingForAppointement = (item: DocRequestData) => {
        setInitModal(false);
        setSelectedAppointement(item);
        setAppointementDataShowModal(true);
    };

    const handleAppointementScheduling = (item: DocRequestData) => {
        setSelectedAppointement(item);

        getTheClosestAvailableDates()
            .then(function (response) { return response; })
            .then(function (data) {
                const items = data;
                setDatesSuggestions(items ?? [])
            })
        setModalType(ModalTypeEnum.Accept);
        setActionModalOpened(true);
    };

    const selectOrResetFilters = () => {
        if (getCurrentEnabledFilter() > 0) {
            handleTypeSelectionChange([
                {
                    label: "Médecine traitante",
                    shortlabel: "MT",
                    value: false
                },
                {
                    label: "Consultation",
                    shortlabel: "Consult",
                    value: false
                }
            ])
            handleStateSelectionChange([
                {
                    label: "Urgence",
                    shortlabel: "Urgence",
                    value: false
                },
                {
                    label: "Moyenne",
                    shortlabel: "Moyenne",
                    value: false
                },
                {
                    label: "Faible",
                    shortlabel: "Faible",
                    value: false
                }
            ])
            handleSituationSelectionChange([
                {
                    label: "Enceinte",
                    shortlabel: "Enceinte",
                    value: false
                },
                {
                    label: "Handicap",
                    shortlabel: "Handicap",
                    value: false
                },
                {
                    label: "Mobile",
                    shortlabel: "Mobile",
                    value: false
                },
                {
                    label: "Affection Longue Durée (ALD)",
                    shortlabel: "Affection Longue Durée (ALD)",
                    value: false
                }
            ])
        } else {
            handleTypeSelectionChange([
                {
                    label: "Médecine traitante",
                    shortlabel: "MT",
                    value: true
                },
                {
                    label: "Consultation",
                    shortlabel: "Consult",
                    value: true
                }
            ])
            handleStateSelectionChange([
                {
                    label: "Urgence",
                    shortlabel: "Urgence",
                    value: true
                },
                {
                    label: "Moyenne",
                    shortlabel: "Moyenne",
                    value: true
                },
                {
                    label: "Faible",
                    shortlabel: "Faible",
                    value: true
                }
            ])
            handleSituationSelectionChange([
                {
                    label: "Enceinte",
                    shortlabel: "Enceinte",
                    value: true
                },
                {
                    label: "Handicap",
                    shortlabel: "Handicap",
                    value: true
                },
                {
                    label: "Mobile",
                    shortlabel: "Mobile",
                    value: true
                },
                {
                    label: "Affection Longue Durée (ALD)",
                    shortlabel: "Affection Longue Durée (ALD)",
                    value: true
                }
            ])
        }
    }

    const manageFiltersSelection = () => {
        // Request State Filter
        const storedStateItems = localStorage.getItem('selectedStateItems');
        setSelectedStateItems(
            storedStateItems ? JSON.parse(storedStateItems) : [
                {
                    label: "Urgence",
                    value: true
                },
                {
                    label: "Moyenne",
                    value: true
                },
                {
                    label: "Faible",
                    value: true
                }
            ]
        )

        // Request Type Filter
        const storedTypeItems = localStorage.getItem('selectedTypeItems');
        setSelectedTypeItems(
            storedTypeItems ? JSON.parse(storedTypeItems) : [
                {
                    label: "Médecine traitante",
                    value: true
                },
                {
                    label: "Consultation",
                    value: true
                }
            ]
        )

        // Request Situation Filter
        const storedSituationItems = localStorage.getItem('selectedSituationItems');
        setSelectedSituationItems(
            storedSituationItems ? JSON.parse(storedSituationItems) : [
                {
                    label: "Enceinte",
                    value: true
                },
                {
                    label: "Handicap",
                    value: true
                },
                {
                    label: "Mobile",
                    value: true
                },
                {
                    label: "Affection Longue Durée (ALD)",
                    value: false
                }
            ]
        )
    }

    const getCurrentEnabledFilter = () => {
        let enabledFilters = 0

        enabledFilters += selectedTypeItems.filter(option => option.value === true).length;
        enabledFilters += selectedStateItems.filter(option => option.value === true).length;
        enabledFilters += selectedSituationItems.filter(option => option.value === true).length;

        return enabledFilters;
    };

    const handleDeleteAppointement = async () => {
        setIsActionsDisabled(true);
        try {
            const response = await appointementDashboardViewModel.deleteAppointement(selectedAppointement?._id ?? "");
            setIsActionsDisabled(false);
            setIsDataLoading(false);
            setActionModalOpened(false);
            setModalType(ModalTypeEnum.Nothing);
            closeAppointementDataShowModal();
            //filterAppointements();
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            setActionModalOpened(false);
            setModalType(ModalTypeEnum.Error);
            setActionModalOpened(true);
        }
    }

    const handleAssignmentAppointement = async () => {
        setIsActionsDisabled(true);
        try {
            const emailTitle = "Demande de prise en charge en tant que médecin traitant";
            const emailContent = `
                <span
                    style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100%;"></span>
                    <p
                        style="color:#3E5981; font-size:15px;line-height:24px; margin:0; text-align: left;">
                        Madame/Monsieur,<br /><br />

                        Je me permets de vous contacter par l'intermédiaire de l'application Doctraitant pour vous soumettre une demande de prise en charge en tant que médecin traitant.<br /><br />
                        
                        L'objectif de cette démarche est de trouver un médecin traitant qualifié pour assurer le suivi médical d'un patient, actuellement en recherche de prise en charge.<br /><br />

                        Pour vous permettre d'évaluer ma demande, je vous joins un lien vers la fiche patient au sein de l'application Doctraitant. Vous y trouverez toutes les informations nécessaires à une première évaluation.<br /><br />
                        
                        <a style="color:#0097B2;" href="https://doctraitant.fr/assignment-requests/${selectedAppointement?._id}/${selectedAppointement?.uuid}">Veuillez ouvrir ce lien</a><br /><br />
                        <a style="color:#0097B2;" href="https://doctraitant.fr/assignment-requests/${selectedAppointement?._id}/${selectedAppointement?.uuid}">https://doctraitant.fr/assignment-requests/${selectedAppointement?._id}/${selectedAppointement?.uuid}</a><br /><br />

                        Je reste à votre disposition pour toute information complémentaire.<br /><br />

                        Je vous remercie par avance pour l'attention que vous porterez à cette demande et espère sincèrement que vous pourrez accéder à la requête.<br /><br />

                        Cordialement,<br /><br />
                        
                        L'équipe DocTraitant
                    </p>`;

            const html = generateAssignmentEmailHtml(emailTitle, emailContent);

            const response = await appointementDashboardViewModel.assignAppointementsRequests(({
                email: emails,
                subject: "Demande de prise en charge en tant que médecin traitant",
                message: html
            }));
            setIsActionsDisabled(false);
            setIsDataLoading(false);
            setActionModalOpened(false);
            setModalType(ModalTypeEnum.Nothing);
            closeAppointementDataShowModal();
        } catch (error) {
            console.error('Error updating user profile in view', error);
            setIsActionsDisabled(false);
            setActionModalOpened(false);
            setModalType(ModalTypeEnum.Error);
            setActionModalOpened(true);
        }
    }
    const fetchPlannedAppointements = async (userId: string, data: any) => {
        setIsDataLoading(true)
        try {
            const response: GeneralAppointementResponse = await appointmentRepository.fetchPlannedAppointments(userId, data);
            setData(response.results.data);
            setIsDataLoading(false)
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
            setIsDataLoading(false)
        }
    };

    const fetchAllPendingAppointements = async (token: string, data: any) => {
        setIsActionsDisabled(true)
        setIsDataLoading(true)
        try {
            const response: GeneralAppointementResponse = await appointmentRepository.fetchPendingAppointments(token, data);
            const filteredData = response.results.data.filter(data => {
                // Check priority filter
                const priorityFilter = selectedStateItems
                    .filter(option => option.value === true)
                    .map(option => option.label)
                    .includes(data.priority);

                const typeFilter = selectedTypeItems
                    .filter(option => option.value === true)
                    .map(option => option.shortlabel)
                    .includes(data.requestType);

                // Check pregnant, ALD, mobile, and handicapped conditions
                const isPregnant = selectedSituationItems.find(option => option.label === 'Enceinte')?.value;
                const isALD = selectedSituationItems.find(option => option.label === 'Affection Longue Durée (ALD)')?.value;
                const isMobile = selectedSituationItems.find(option => option.label === 'Mobile')?.value;
                const isHandicapped = selectedSituationItems.find(option => option.label === 'Handicap')?.value;

                const situationFilter =
                    (isPregnant === undefined || data.isPregnant === isPregnant) ||
                    (isALD === undefined || data.isALD === isALD) ||
                    (isMobile === undefined || data.isMobile === isMobile) ||
                    (isHandicapped === undefined || data.isHandicapped === isHandicapped);

                // Check zipcode filter
                const zipcodeFilter = zipcode.length === 0 || (data.zipcode && zipcode.includes(data.zipcode));

                // Return true if all filters are satisfied
                return typeFilter && priorityFilter && situationFilter && zipcodeFilter;
            });

            setData(filteredData);
            setIsActionsDisabled(false)
            setIsDataLoading(false)
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error);
            setIsActionsDisabled(false)
            setIsDataLoading(false)
        }
    };

    useEffect(() => {
        document.title = strings.dashboard_title_tab;
    }, [])

    useEffect(() => {
        initFilterTriggerAction();

        fetchAllPendingAppointements("", {});

        socket.on("appointmentRequestCreated", (newAppointment) => {
            if (newAppointment.meetingDateTime == null) {
                setData((prevAppointments) => (Array.isArray(prevAppointments) ? [...prevAppointments, newAppointment] : [newAppointment]));
                const filteredData = data.filter(data => {
                    // Check priority filter
                    const priorityFilter = selectedStateItems
                        .filter(option => option.value === true)
                        .map(option => option.label)
                        .includes(data.priority);

                    const typeFilter = selectedTypeItems
                        .filter(option => option.value === true)
                        .map(option => option.shortlabel)
                        .includes(data.requestType);

                    // Check pregnant, ALD, mobile, and handicapped conditions
                    const isPregnant = selectedSituationItems.find(option => option.label === 'Enceinte')?.value;
                    const isALD = selectedSituationItems.find(option => option.label === 'Affection Longue Durée (ALD)')?.value;
                    const isMobile = selectedSituationItems.find(option => option.label === 'Mobile')?.value;
                    const isHandicapped = selectedSituationItems.find(option => option.label === 'Handicap')?.value;

                    const situationFilter =
                        (isPregnant === undefined || data.isPregnant === isPregnant) ||
                        (isALD === undefined || data.isALD === isALD) ||
                        (isMobile === undefined || data.isMobile === isMobile) ||
                        (isHandicapped === undefined || data.isHandicapped === isHandicapped);

                    // Check zipcode filter
                    const zipcodeFilter = zipcode.length === 0 || (data.zipcode && zipcode.includes(data.zipcode));

                    // Return true if all filters are satisfied
                    return typeFilter && priorityFilter && situationFilter && zipcodeFilter;
                });
                // setData(filteredData);
                fetchAllPendingAppointements("", {});
            }
        });

        socket.on("appointmentRequestUpdated", (updatedTask) => {
            fetchAllPendingAppointements("", {});
        });

        return () => {
            socket.disconnect();
        };
    }, [zipcode, selectedTypeItems, selectedStateItems, selectedSituationItems])

    const handleZipcodeSelectionChange = (filtredZipCode: string[] | undefined) => {
        setZipcode(filtredZipCode ?? []);
    };

    const handleEmailsSelectionChange = (emails: string[] | undefined) => {
        setEmails(emails);
    };

    useEffect(() => {
        if (appointementDataShowModal || schedulingModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [appointementDataShowModal, schedulingModal]);




    //FORM MULTI STEP
    const [step, setStep] = useState(1);
    const [loadingDataServer, setLoadingDataServer] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        adresse: '',
        zipcode: '',
        phone: ''
    });

    const nextStep = () => {
        if (step > 2) {
            setLoadingDataServer(true);
            setStep(prevStep => prevStep + 1);
        } else {
            setStep(prevStep => prevStep + 1);
        }
    };

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const submitForm = () => {
        alert('Form submitted!');
        console.log(formData);
        // Handle form submission
    };


    // To delete 
    const [isOpen, setIsOpen] = useState(true);

    const [activeTab, setActiveTab] = useState<string>('tab-1');

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    return (
        <header>
            <LayoutHeader
                icon={
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 text-[#22a6b3]"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                        />
                    </svg>
                }
                title="Vue d'ensemble"
                subtitle="Demandes en cours"
            />

            <div
                className={`${isOpen ? '' : 'hidden'
                    }`}
            >
                <main id="content">

                    <nav className="sticky -top-px bg-slate-50 flex pt-5 pl-5 pr-5 pb-3 z-40">

                        <div className=" w-full">

                            <div className="mt-8 py-4">
                                <div className="relative flex items-center justify-between">
                                    <div className="absolute inset-y-0 right-0 flex items-center">
                                        <div className="relative flex flex-row">
                                            <button
                                                onClick={() => {
                                                    setModalType(ModalTypeEnum.FilterRequest)
                                                    setActionModalOpened(true)
                                                }}
                                                type="button" className="relative inline-flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 bg-white text-sky-700 shadow-sm disabled:opacity-50 disabled:pointer-events-none p-2">
                                                <svg className="flex-shrink-0 w-3.5 h-3.5 mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M3 6h18" /><path d="M7 12h10" /><path d="M10 18h4" /></svg>
                                                Filtres avancés
                                                <span className="absolute top-0 end-0 inline-flex items-center p-1 pl-2 pr-2 text-xs font-medium transform -translate-y-1/2 translate-x-1/2 rounded-lg bg-[#12CBC4]/70 text-teal-700 -ml-1">{getCurrentEnabledFilter()}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Searchbar selectedZipCode={zipcode} onSelectedZipCodeChange={handleZipcodeSelectionChange} />
                        </div>
                    </nav>

                    <div className="p-4 flex-1 bg-slate-50">
                        <div className="space-y-4">
                            {/* Tabs content */}
                            {activeTab === 'tab-1' && (
                                <div className="space-y-4">
                                    {/* Skeleton UI for data loading  */}
                                    {
                                        isDataLoading ? (
                                            <>
                                                <div className="space-y-4">
                                                    <div className="shadow-sm rounded p-4 mt-4 mx-auto">
                                                        <div className="w-full animate-pulse flex space-x-4">
                                                            <div className="flex-1 space-y-4 py-1">
                                                                <div className="h-6 bg-slate-300/80 rounded"></div>
                                                                <div className="space-y-3">
                                                                    <div className="h-5 bg-slate-300/80 rounded"></div>
                                                                    <div className="grid grid-cols-3 gap-4">
                                                                        <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                                        <div className="grid grid-cols-3 gap-4">
                                                                            <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                                            <div className="h-5 bg-slate-300/80 rounded col-span-1"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-sm rounded p-4 mt-4 mx-auto">
                                                        <div className="w-full animate-pulse flex space-x-4">
                                                            <div className="flex-1 space-y-4 py-1">
                                                                <div className="h-6 bg-slate-300/80 rounded"></div>
                                                                <div className="space-y-3">
                                                                    <div className="h-5 bg-slate-300/80 rounded"></div>
                                                                    <div className="grid grid-cols-3 gap-4">
                                                                        <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                                        <div className="grid grid-cols-3 gap-4">
                                                                            <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                                            <div className="h-5 bg-slate-300/80 rounded col-span-1"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex flex-col space-y-2 w-full">
                                                {Array.isArray(data) && data.length > 0 ? (
                                                    <div className="flex flex-col">
                                                        <div className="-m-1.5 overflow-x-auto">
                                                            <div className="p-1.5 min-w-full inline-block align-middle">
                                                                <div className="grid grid-cols-3 gap-3">
                                                                    {data.map((item) => (
                                                                        <RequestItem
                                                                            onShowDetailsPress={() => handleDataDisplayingForAppointement(item)}
                                                                            onValdiationPress={() => handleAppointementScheduling(item)}
                                                                            key={item._id}
                                                                            data={item}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    // No Zipcode in the selection 
                                                    <div className="h-20">
                                                        <span className="mb-2 inline-flex justify-center items-center">
                                                            <img
                                                                src={emptySearch}
                                                                alt="Logo de l'application"
                                                                className="h-40 rounded-md"
                                                            />
                                                        </span>

                                                        <h3 className="mb-4 text-base font-light italic text-gray-400">
                                                            Aucune demande disponible
                                                        </h3>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                            )}
                            {activeTab === 'tab-2' && (
                                <div className="space-y-4">





                                </div>
                            )}
                            {activeTab === 'tab-3' && (
                                <div>
                                    <div>
                                        <div className="flex gap-x-3 pl-3">


                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab-4' && (
                                <div className="space-y-4">
                                    <div className="flex justify-start pl-1 pr-1">
                                        <div className="w-full">

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
                {/* Appointement Data Displaying Modal  */}
                <CustomPatientModalWithScroll
                    open={appointementDataShowModal}
                    initModal={initModal}
                    title="Filtres"
                    onClose={() => (false)}
                    header={
                        <div className="p-4 flex justify-between items-center">
                            <h3 className="flex items-center gap-x-2 font-bold text-white">
                                <SnippetFolderRoundedIcon fontSize="medium" />
                                Dossier du patient
                            </h3>
                            <button onClick={() =>
                                closeAppointementDataShowModal()
                            } type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:focus:outline-none" data-hs-overlay="#hs-basic-modal">
                                <span className="sr-only">Close</span>
                                <CancelRoundedIcon fontSize="medium" />
                            </button>
                        </div>
                    }
                    subHeader={
                        <div className="mx-auto flex justify-center items-center pl-4">
                            <PriorityTag useCase={""} criticity={selectedAppointement?.priority ?? "N/A"} />
                        </div>
                    }
                    informations={
                        <div className="space-y-4">
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nom et Prénom</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {`${selectedAppointement?.firstname ?? "N/A"} ${selectedAppointement?.lastname ?? "N/A"}`}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Date de naissance</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement && `${getFormalDateWithDayAsNumberShortcutMonthAndYear(selectedAppointement.birthdate.toLocaleString()) ?? "N/A"} - ${calculateAge(convertToISO8601Date(selectedAppointement.birthdate ?? "N/A"))} an(s)`}
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nature de la demande</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {`${getTheRightRequestType(selectedAppointement?.requestType ?? "")}`}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Classification</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.priority ?? "N/A"}
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Informations complémentaires</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.requestDescription ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    contact={
                        <div className="space-y-4">
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.city ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Commune</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.city ?? "N/A"}
                                    </p>
                                </div>
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Code postal</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.zipcode ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse e-mail</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.email ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-start space-x-4">
                                <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Téléphone</h3>
                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                        {selectedAppointement?.phone ?? "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    history={
                        <div className="p-4 sm:p-6 text-center overflow-y-auto">
                            <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500 dark:bg-yellow-700 dark:border-yellow-600 dark:text-yellow-100">
                                <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                            </span>
                            <h3 className="mb-2 text-xl font-semibold text-[#ff4757]/90">
                                Indisponible
                            </h3>
                            <p className="text-[#2f3542]/80 font-normal">
                                Aperçu temporairement indisponible
                            </p>
                        </div>
                    }
                    actions={
                        <div className="text-center overflow-y-auto">
                            <div className="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
                                <div className="hs-accordion pb-3">
                                    <div className="w-full rounded-md pt-2">
                                        <h3 className="text-sm font-medium text-gray-600/90 text-left flex items-center">
                                            <span>Prendre en charge cette demande</span>
                                        </h3>
                                        <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                            <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                                Attribuer un rendez-vous afin de prendre de prise en charge le patient.
                                            </h3>
                                            <div className="inline-flex gap-x-2">
                                                <p
                                                    onClick={() => {
                                                        setModalType(ModalTypeEnum.Accept)
                                                        setActionModalOpened(true)
                                                    }}
                                                    className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                                    Accepter
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hs-accordion pb-3">
                                    <div className="w-full rounded-md pt-4">
                                        <h3 className="text-sm font-medium text-gray-600/90 text-left flex items-center">
                                            <span>Assigner cette demande</span>
                                        </h3>
                                        <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                            <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                                Attribuer cette demande de prise en charge à un praticien.
                                            </h3>
                                            <div className="inline-flex gap-x-2">
                                                <p
                                                    onClick={() => {
                                                        setModalType(ModalTypeEnum.Assign)
                                                        setActionModalOpened(true)
                                                    }}
                                                    className="py-2 px-2 inline-flex items-center text-sm font-medium text-sky-700 cursor-pointer">
                                                    Assigner
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hs-accordion pb-3">
                                    <div className="w-full rounded-md pt-4">
                                        <h3 className="text-sm font-medium text-gray-600/90 text-left flex items-center">
                                            <span>Supprimer cette demande</span>
                                        </h3>
                                        <div className="mt-1 text-gray-600 font-normal flex text-left text-xs justify-between items-center">
                                            <h3 className="flex items-center gap-x-2 font-light text-xs text-black">
                                                Supprimer définitivement cette demande de prise en charge.
                                            </h3>
                                            <div className="inline-flex gap-x-2">
                                                <p
                                                    onClick={() => {
                                                        setModalType(ModalTypeEnum.Delete)
                                                        setActionModalOpened(true)
                                                    }}
                                                    className="py-2 px-2 inline-flex items-center text-sm font-medium text-[#FC5C65] cursor-pointer">
                                                    Supprimer
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    footer={
                        <div className="p-4 flex justify-end gap-x-2">
                            <p onClick={() => closeAppointementDataShowModal()} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border font-medium bg-white text-gray-700 shadow-sm align-middle transition-all text-sm cursor-pointer">
                                <CloseRoundedIcon fontSize="medium" />
                                Annuler
                            </p>
                            <p onClick={() => closeAppointementDataShowModal()} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold cursor-pointer rounded-lg bg-[#12CBC4] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none">
                                <CheckRoundedIcon fontSize="medium" />
                                Valider
                            </p>
                        </div>
                    }
                >
                </CustomPatientModalWithScroll>

                {/* Ask user for action with modal */}
                <Modal
                    open={actionModalOpened}
                    title=""
                    onClose={() => setActionModalOpened(false)}>

                    {modalType === ModalTypeEnum.Assign &&
                        <AssignRequestView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }}
                            onConfirmClick={() => {
                                handleAssignmentAppointement()
                            }}
                            isActionsDisabled={isActionsDisabled}
                            emails={emails}
                            handleEmailsSelectionChange={handleEmailsSelectionChange}
                        />
                    }
                    {modalType === ModalTypeEnum.Error &&
                        <ErrorView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }} />
                    }
                    {modalType === ModalTypeEnum.Delete &&
                        <DeleteAppointementView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }}
                            onConfirmClick={handleDeleteAppointement} />
                    }
                    {modalType === ModalTypeEnum.Accept &&
                        <AcceptAppointementView
                            onCloseClick={() => {
                                setIsDataLoading(false);
                                setIsActionsDisabled(false);
                                closeSchedulingModal();
                                setDataLoading(false);
                            }}
                            schedulePreviousForm={schedulePreviousForm}
                            scheduleAppointement={scheduleAppointement}
                            setDateTimeValue={handleDateTimeChange}
                            selectSuggestedDate={selectSuggestedDate}
                            handlePhoneInputChange={handlePhoneInputChange}
                            handleEmailInputChange={handleEmailInputChange}
                            handleAdresseInputChange={handleAdresseInputChange}
                            handleCityInputChange={handleCityInputChange}
                            handleCommuneInputChange={handleCommuneInputChange}
                            handleZipcodeInputChange={handleZipcodeInputChange}
                            dateTimeValue={dateTimeValue}
                            datesSuggestions={datesSuggestions}
                            isActionsDisabled={isActionsDisabled}
                            schedulingForm={schedulingForm}
                            phoneValue={phoneValue}
                            emailValue={emailValue}
                            cityValue={cityValue}
                            communeValue={communeValue}
                            zipcodeValue={zipcodeValue}
                            adresseValue={adresseValue}
                            dataLoading={dataLoading} />
                    }
                    {modalType === ModalTypeEnum.FilterRequest &&
                        <FilterRequestView
                            onCloseClick={() => {
                                setModalType(ModalTypeEnum.Nothing)
                                setActionModalOpened(false)
                            }}
                            selectOrResetFilters={selectOrResetFilters}
                            handleTypeSelectionChange={handleTypeSelectionChange}
                            handleStateSelectionChange={handleStateSelectionChange}
                            handleSituationSelectionChange={handleSituationSelectionChange}
                            isActionsDisabled={isActionsDisabled}
                            isResetOrSelectStatusFilters={isResetOrSelectStatusFilters}
                            selectedTypeItems={selectedTypeItems}
                            selectedStateItems={selectedStateItems}
                            selectedSituationItems={selectedSituationItems} />
                    }
                </Modal>
            </div>
        </header>
    )
}
export default AppointementDashboardView

function handleShowToast(arg0: string, arg1: string) {
    throw new Error('Function not implemented.');
}
