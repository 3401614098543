import React, { useState, useEffect } from 'react';
import { LayoutsEnum } from './../../types/layoutsEnum';
import version from './../../../package.json';
// Icons imports
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import appLogo from './../../images/app_logo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

interface SidebarProps {
    onPageChange: (layoutEnum: LayoutsEnum) => void;
    toggle: boolean;
    selectedView: string;
    toggleBoolean: () => void;
}
//edit
const Sidebar: React.FC<SidebarProps> = ({ onPageChange, toggle, selectedView, toggleBoolean }: SidebarProps) => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<React.ReactNode | null>(null);
    const [lastname, setLastname] = useState<React.ReactNode | null>(localStorage.getItem("lastname"));
    const [firstname, setFirstname] = useState<string | null>(localStorage.getItem("firstname"));
    const [userPermissions, setUserPermissions] = useState<string | null>(localStorage.getItem("userRights"));

    useEffect(() => {
        if (localStorage.getItem("currentView") && localStorage.getItem("currentView") != "") {
            console.log("current view")
        } else {
            viewNavigation(LayoutsEnum.AppointementsRequest)
        }
    }, []);

    useEffect(() => {
        if (selectedView == "user-account") {
            viewNavigation(LayoutsEnum.Account);
        }
    }, [selectedView]);

    function viewNavigation(layout: LayoutsEnum) {
        if (layout === LayoutsEnum.AppointementsRequest) {
            setActiveItem('tableau-des-demandes');
        } else if (layout === LayoutsEnum.Account) {
            setActiveItem('gestion-du-compte');
        } else if (layout === LayoutsEnum.Appointements) {
            setActiveItem('gestion-des-rdv');
        } else if (layout === LayoutsEnum.TeamSupport) {
            setActiveItem('support-du-compte');
        } else if (layout === LayoutsEnum.AdminBoard) {
            setActiveItem('gestion-dadmin');
        } else if (layout === LayoutsEnum.ControlCenter) {
            setActiveItem('control-center');
        } else {
            setActiveItem('tableau-des-demandes');
        }
        onPageChange(layout);
        toggleBoolean();
    };

    return (
        <div>
            <div
                id="application-sidebar"
                className={`hs-overlay hs-overlay-open:translate-x-0 ${toggle
                    ? 'transform translate-x-0 transition-transform ease-in-out duration-300'
                    : 'transform -translate-x-full transition-transform ease-in-out duration-300'
                    } fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0`}
            >
                <div className="px-4 flex justify-between items-center">
                    <img
                        src={appLogo}
                        alt="Logo de l'application"
                        className="h-16 rounded-md"
                    />
                    <button
                        className="text-gray-500 hover:text-gray-700 dark:text-white dark:hover:text-gray-200 lg:hidden"
                        onClick={toggleBoolean}
                    >
                        <MenuOpenRoundedIcon htmlColor="#22a6b3" fontSize="large" />
                    </button>
                </div>
                <nav
                    className="hs-accordion-group p-5 pt-6 w-full flex flex-col flex-wrap"
                    data-hs-accordion-always-open=""
                >
                    <ul className="space-y-1.5">
                        <li>
                            <a
                                className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem === 'tableau-des-demandes' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                onClick={() => viewNavigation(LayoutsEnum.AppointementsRequest)}
                                style={{ cursor: 'pointer' }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                                </svg>
                                Demandes
                            </a>
                        </li>
                        <li className="hs-accordion" id="account-accordion">
                            <a
                                className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem == 'gestion-du-compte' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                onClick={() => viewNavigation(LayoutsEnum.Account)}
                                style={{ cursor: 'pointer' }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                Gestion du compte
                            </a>
                        </li>
                        {userPermissions && userPermissions.includes("Medecin") && (
                            <li className="hs-accordion" id="projects-accordion">
                                <a
                                    className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem == 'gestion-des-rdv' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                    onClick={() => viewNavigation(LayoutsEnum.Appointements)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                                    </svg>
                                    Mes rendez-vous
                                </a>
                            </li>
                        )}
                        {userPermissions && userPermissions.includes("Medecin") && (
                            <li>
                                <a
                                    className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem == 'support-du-compte' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                    onClick={() => viewNavigation(LayoutsEnum.TeamSupport)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288" />
                                    </svg>
                                    Support
                                </a>
                            </li>
                        )}
                        {userPermissions && userPermissions.includes("Admin") && (
                            <li className="hs-accordion" id="projects-accordion">
                                <a
                                    className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem == 'gestion-dadmin' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                    onClick={() => viewNavigation(LayoutsEnum.AdminBoard)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`}>
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="7" height="5" rx="0.6" transform="matrix(0 -1 -1 0 22 21)" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`} strokeWidth="1.5"></rect> <rect width="7" height="5" rx="0.6" transform="matrix(0 -1 -1 0 7 15.5)" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`} strokeWidth="1.5"></rect> <rect width="7" height="5" rx="0.6" transform="matrix(0 -1 -1 0 22 10)" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`} strokeWidth="1.5"></rect> <path d="M17 17.5H13.5C12.3954 17.5 11.5 16.6046 11.5 15.5V8.5C11.5 7.39543 12.3954 6.5 13.5 6.5H17" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`} strokeWidth="1.5"></path> <path d="M11.5 12H7" stroke={`${activeItem == 'gestion-dadmin' ? "#22a6b3" : "#000000"}`} strokeWidth="1.5"></path> </g>
                                    </svg>
                                    Administration
                                </a>
                            </li>
                        )}
                        {userPermissions && userPermissions.includes("Admin") && (
                            <li className="hs-accordion" id="projects-accordion">
                                <a
                                    className={`flex items-center gap-x-3.5 py-2 px-2.5 text-base ${activeItem == 'control-center' ? "bg-gray-200/40 text-[#22a6b3] font-normal rounded-xl" : "font-light"}`}
                                    onClick={() => viewNavigation(LayoutsEnum.ControlCenter)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="controlCentreIconTitle" stroke={`${activeItem == 'control-center' ? "#22a6b3" : "#000000"}`} strokeWidth="1.44" strokeLinecap="square" strokeLinejoin="miter" color="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title id="controlCentreIconTitle">Control Centre</title> <path d="M4 6.5C4 4.567 5.567 3 7.5 3L16.5 3C18.433 3 20 4.567 20 6.5V6.5C20 8.433 18.433 10 16.5 10L7.5 10C5.567 10 4 8.433 4 6.5V6.5Z"></path> <path d="M20 17.5C20 19.433 18.433 21 16.5 21L7.5 21C5.567 21 4 19.433 4 17.5V17.5C4 15.567 5.567 14 7.5 14L16.5 14C18.433 14 20 15.567 20 17.5V17.5Z"></path> <circle cx="16.5" cy="17.5" r="1"></circle> <circle cx="7.5" cy="6.5" r="1"></circle> </g></svg>
                                    Centre de contrôle
                                </a>
                            </li>
                        )}
                    </ul>
                </nav>
                <div className="absolute bottom-0 left-0 right-0 text-center text-black dark:text-white p-2">
                    <div className="border-t flex p-2.5">
                        <AccountCircleIcon htmlColor="#22a6b3" fontSize="large" />
                        <div className={`flex justify-between items-center overflow-hidden transition-all w-52 ml-3`}>
                            <div className="">
                                <h3 className="font-normal text-sm text-gray-800 text-start pb-1.5">{`${firstname} ${lastname}`}</h3>
                                <a
                                    onClick={() => viewNavigation(LayoutsEnum.Account)}
                                    className="flex items-center text-sm text-gray-500 font-light cursor-pointer"
                                >
                                    <span>Informations</span>
                                    <ChevronRightRoundedIcon htmlColor="#9ca3af" fontSize="small" />
                                </a>
                            </div>
                            <svg
                                onClick={() => viewNavigation(LayoutsEnum.Account)}
                                className="w-6 h-6 cursor-pointer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3.08168 13.9445C2.55298 12.9941 2.28862 12.5188 2.28862 12C2.28862 11.4812 2.55298 11.0059 3.08169 10.0555L4.43094 7.63L5.85685 5.24876C6.4156 4.31567 6.69498 3.84912 7.14431 3.5897C7.59364 3.33028 8.13737 3.3216 9.22483 3.30426L12 3.26L14.7752 3.30426C15.8626 3.3216 16.4064 3.33028 16.8557 3.5897C17.305 3.84912 17.5844 4.31567 18.1431 5.24876L19.5691 7.63L20.9183 10.0555C21.447 11.0059 21.7114 11.4812 21.7114 12C21.7114 12.5188 21.447 12.9941 20.9183 13.9445L19.5691 16.37L18.1431 18.7512C17.5844 19.6843 17.305 20.1509 16.8557 20.4103C16.4064 20.6697 15.8626 20.6784 14.7752 20.6957L12 20.74L9.22483 20.6957C8.13737 20.6784 7.59364 20.6697 7.14431 20.4103C6.69498 20.1509 6.4156 19.6843 5.85685 18.7512L4.43094 16.37L3.08168 13.9445Z" stroke="#222222"></path> <circle cx="12" cy="12" r="3" stroke="#222222"></circle> </g></svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar