
import React, { useState } from 'react';
import strings from '../../assets/strings';
// Icons imports
import demoAppScreen from './../../images/app_screen_main.jpg';
import howItWorkMockup from './../../images/how_it_work_mockup.png';
import doctorTraitantImage from './../../images/doctor_traitant_image.png';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import ShieldRoundedIcon from '@mui/icons-material/ShieldRounded';
import familyImage from './../../images/family_image.png';
import doctorCarePeople from './../../images/doctor_care_people.png';
import mobileAppImage from './../../images/mobile_app_image.png';
import secureDataImage from './../../images/data_security.png';

export const Main = () => {

  const [activeTab, setActiveTab] = useState<string>('tab-1');

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  return (
    <div className="text-gray-600 body-font">
      <div className="max-w-[85rem] mx-auto px-5 py-24 sm:px-6 lg:px-8" id="home-doctraitant">
        <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
          <div>
            <h1 className="block text-3xl font-bold text-[#1E6091] sm:text-4xl lg:text-6xl lg:leading-tight">{strings.main_header_find_label} <span className="text-[#0097B2]">{strings.main_header_doctor_label}</span></h1>
            <p className="mt-3 text-lg text-gray-500">{strings.main_header_description_label}</p>
            <div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-6">
              <a href="#"
                className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                <svg className="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple"
                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                  </path>
                </svg>
                <div className="text-left">
                  <div className="mb-1 text-xs">{strings.download_from_label}</div>
                  <div className="-mt-1 font-sans text-sm font-semibold">{strings.app_store_label}</div>
                </div>
              </a>
              <a href="#"
                className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                <svg className="mr-3 w-7 h-7" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="#32BBFF" d="M382.369,175.623C322.891,142.356,227.427,88.937,79.355,6.028 C69.372-0.565,57.886-1.429,47.962,1.93l254.05,254.05L382.369,175.623z"></path> <path fill="#32BBFF" d="M47.962,1.93c-1.86,0.63-3.67,1.39-5.401,2.308C31.602,10.166,23.549,21.573,23.549,36v439.96 c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314L302.012,255.98L47.962,1.93z"></path> <path fill="#32BBFF" d="M302.012,255.98L47.956,510.035c9.927,3.384,21.413,2.586,31.399-4.103 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L302.012,255.98z"></path> </g> <path fill="#2C9FD9" d="M23.549,255.98v219.98c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314 L302.012,255.98H23.549z"></path> <path fill="#29CC5E" d="M79.355,6.028C67.5-1.8,53.52-1.577,42.561,4.239l255.595,255.596l84.212-84.212 C322.891,142.356,227.427,88.937,79.355,6.028z"></path> <path fill="#D93F21" d="M298.158,252.126L42.561,507.721c10.96,5.815,24.939,6.151,36.794-1.789 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L298.158,252.126z"></path> <path fill="#FFD500" d="M488.45,255.98c0-12.19-6.151-24.492-18.342-31.314c0,0-22.799-12.721-92.682-51.809l-83.123,83.123 l83.204,83.205c69.116-38.807,92.6-51.892,92.6-51.892C482.299,280.472,488.45,268.17,488.45,255.98z"></path> <path fill="#FFAA00" d="M470.108,287.294c12.191-6.822,18.342-19.124,18.342-31.314H294.303l83.204,83.205 C446.624,300.379,470.108,287.294,470.108,287.294z"></path> </g></svg>
                <div className="text-left">
                  <div className="mb-1 text-xs">{strings.available_on_label}</div>
                  <div className="-mt-1 font-sans text-sm font-semibold">{strings.google_play_label}</div>
                </div>
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img className="w-10/12 rounded-md" src={demoAppScreen} alt="Image Description" />
          </div>
        </div>
      </div>

      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" id="why-doctraitant">
        <div className="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
          <div className="lg:col-span-5 lg:pl-6">
            <div className="flex justify-center items-center">
              <img className="w-10/12 rounded-md" src={doctorTraitantImage} alt="Image Description" />
            </div>
          </div>

          <div className="mt-5 sm:mt-10 lg:mt-0 lg:col-span-7">
            <div className="space-y-6 sm:space-y-8">
              <div className="space-y-2 md:space-y-4">
                <h2 className="text-2xl text-[#1E6091] font-bold sm:text-3xl pb-2">
                  Pourquoi un Médecin Traitant ?
                </h2>
                <p className="text-gray-500 dark:text-neutral-500">
                  Avoir un médecin traitant est essentiel pour bénéficier d'un suivi médical régulier, d'une coordination efficace des soins, et d'un accès facilité à des conseils personnalisés et à des traitements appropriés tout au long de votre parcours de santé.
                </p>
              </div>

              <ul className="space-y-2 sm:space-y-4">
                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                  </span>

                  <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                    <span className="font-medium">Suivi médical continu et régulier</span>
                  </span>
                </li>

                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                  </span>

                  <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                    <span className="font-medium">Coordination efficace</span>
                  </span>
                </li>

                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                  </span>

                  <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                    <span className="font-medium">Accès rapide à des soins de qualité</span>
                  </span>
                </li>

                <li className="flex space-x-3">
                  <span className="mt-0.5 size-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                    <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                  </span>

                  <span className="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                    <span className="font-medium">Un meilleur remboursement des frais médicaux</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" id="feature-doctraitant">
        <div className="relative p-6 md:p-16">
          <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
            <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
              <h2 className="text-2xl text-[#1E6091] font-bold sm:text-3xl">
                {strings.main_header_explore_the_app_label}
              </h2>

              <nav className="grid gap-4 mt-5 md:mt-10" aria-label="Tabs" role="tablist">
                <button onClick={() => handleTabClick('tab-1')} type="button" className={`hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${activeTab === 'tab-1' ? 'active' : ''}`} id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1" role="tab">
                  <span className="flex">
                    <span className="hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">
                      <GridViewRoundedIcon fontSize="medium" />
                    </span>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">{strings.main_header_explore_the_app_ui_label}</span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">{strings.main_header_explore_the_app_ui_description_label}</span>
                    </span>
                  </span>
                </button>

                <button onClick={() => handleTabClick('tab-2')} type="button" className={`hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${activeTab === 'tab-2' ? 'active' : ''}`} id="tabs-with-card-item-2" data-hs-tab="#tabs-with-card-2" aria-controls="tabs-with-card-2" role="tab">
                  <span className="flex">
                    <span className="hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">
                      <HealthAndSafetyRoundedIcon fontSize="medium" />
                    </span>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">{strings.main_header_explore_the_app_healthcare_label}</span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">{strings.main_header_explore_the_app_healthcare_description_label}</span>
                    </span>
                  </span>
                </button>

                <button onClick={() => handleTabClick('tab-3')} type="button" className={`hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${activeTab === 'tab-3' ? 'active' : ''}`} id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
                  <span className="flex">
                    <span className="hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">
                      <EventAvailableRoundedIcon fontSize="medium" />
                    </span>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">{strings.main_header_explore_the_app_planning_label}</span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">{strings.main_header_explore_the_app_planning_description_label}</span>
                    </span>
                  </span>
                </button>

                <button onClick={() => handleTabClick('tab-4')} type="button" className={`hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent text-start hover:bg-gray-200 p-4 md:p-5 rounded-xl dark:hs-tab-active:bg-slate-900 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 ${activeTab === 'tab-4' ? 'active' : ''}`} id="tabs-with-card-item-3" data-hs-tab="#tabs-with-card-3" aria-controls="tabs-with-card-3" role="tab">
                  <span className="flex">
                    <span className="hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">
                      <ShieldRoundedIcon fontSize="medium" />
                    </span>
                    <span className="grow ms-6">
                      <span className="block text-lg font-semibold hs-tab-active:text-[#1E6091] text-gray-800 dark:hs-tab-active:text-[#1E6091] dark:text-gray-200">{strings.main_header_explore_the_app_privacy_label}</span>
                      <span className="block mt-1 text-gray-800 dark:hs-tab-active:text-gray-200 dark:text-gray-200">{strings.main_header_explore_the_app_privacy_description_label}</span>
                    </span>
                  </span>
                </button>
              </nav>
            </div>

            <div className="lg:col-span-6">
              <div className="relative">
                <div>
                  <div className={`relative flex justify-center items-center ${activeTab === 'tab-1' ? '' : 'hidden'}`}>
                    <div className="absolute inset-0 bg-white shadow-xl rounded-xl dark:bg-gray-800"></div>
                    <img src={mobileAppImage} alt="Image Description" className="relative rounded-xl" style={{ marginTop: '4px', marginRight: '4px', marginBottom: '4px' }} />
                  </div>

                  <div className={`relative flex justify-center items-center ${activeTab === 'tab-2' ? '' : 'hidden'}`}>
                    <div className="absolute inset-0 bg-white shadow-xl rounded-xl dark:bg-gray-800"></div>
                    <img src={familyImage} alt="Image Description" className="relative rounded-xl" style={{ marginTop: '4px', marginRight: '4px', marginBottom: '4px' }} />
                  </div>

                  <div className={`relative flex justify-center items-center ${activeTab === 'tab-3' ? '' : 'hidden'}`}>
                    <div className="absolute inset-0 bg-white shadow-xl rounded-xl dark:bg-gray-800"></div>
                    <img src={doctorCarePeople} alt="Image Description" className="relative rounded-xl" style={{ marginTop: '4px', marginRight: '4px', marginBottom: '4px' }} />
                  </div>

                  <div className={`relative flex justify-center items-center ${activeTab === 'tab-4' ? '' : 'hidden'}`}>
                    <div className="absolute inset-0 bg-white shadow-xl rounded-xl dark:bg-gray-800"></div>
                    <img src={secureDataImage} alt="Image Description" className="relative rounded-xl" style={{ marginTop: '4px', marginRight: '4px', marginBottom: '4px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute inset-0 grid grid-cols-12 size-full">
            <div className="col-span-full lg:col-span-7 lg:col-start-6 bg-gray-100 w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full dark:bg-white/[.075]"></div>
          </div>
        </div>
      </div>

      <div className="" id="how-work-doctraitant">
        <div className="max-w-5xl px-4 xl:px-0 py-10 lg:pt-20 lg:pb-20 mx-auto">

          <div className="space-y-2 md:space-y-4 p-5 mb-4">
            <h2 className="text-2xl text-[#1E6091] font-bold sm:text-3xl pb-2">
              {strings.how_it_work_label} ?
            </h2>
            <p className="text-gray-500 dark:text-neutral-500">
              Pour Trouver un médecin traitant via l'application Doctraitant, suivez ces étapes simples et découvrez rapidement une sélection de professionnels de santé disponibles pour répondre à vos besoins médicaux.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
            <div className="flex justify-center items-center">
              <img className="w-10/12 rounded-md" src={howItWorkMockup} alt="How it work" />
            </div>

            <div className="p-4 max-w-xl mx-auto dark:bg-gray-800">
              <div className="flex">
                <div className="mr-4 flex flex-col items-center">
                  <div>
                    <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#1E6091]"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      className="h-6 w-6 text-blue-800 dark:text-slate-200">
                      <path d="M12 5l0 14"></path>
                      <path d="M18 13l-6 6"></path>
                      <path d="M6 13l6 6"></path>
                    </svg></div>
                  </div>
                  <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                </div>
                <div className="pt-1.5 pb-8 text-start">
                  <p className="mb-2 text-base font-bold text-gray-900 dark:text-slate-300">1. Téléchargez et installez l'application</p>
                  <p className="text-gray-600 text-base">Commencez par télécharger l'application Doctraitant depuis votre store habituel (App Store pour iOS ou Google Play pour Android).</p>
                </div>
              </div>

              <div className="flex">
                <div className="mr-4 flex flex-col items-center">
                  <div>
                    <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#1E6091]"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      className="h-6 w-6 text-blue-800 dark:text-slate-200">
                      <path d="M12 5l0 14"></path>
                      <path d="M18 13l-6 6"></path>
                      <path d="M6 13l6 6"></path>
                    </svg></div>
                  </div>
                  <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                </div>
                <div className="pt-1.5 pb-8 text-lef text-start">
                  <p className="mb-2 text-base font-bold text-gray-900 dark:text-slate-300">2. Créez un compte</p>
                  <p className="text-gray-600 text-base">Ouvrez l'application et inscrivez-vous en fournissant les informations nécessaires. La création de compte est rapide et vous permettra d'accéder à toutes les fonctionnalités de l'application.</p>
                </div>
              </div>

              <div className="flex">
                <div className="mr-4 flex flex-col items-center">
                  <div>
                    <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#1E6091]"><svg
                      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                      className="h-6 w-6 text-blue-800 dark:text-slate-200">
                      <path d="M12 5l0 14"></path>
                      <path d="M18 13l-6 6"></path>
                      <path d="M6 13l6 6"></path>
                    </svg></div>
                  </div>
                  <div className="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                </div>
                <div className="pt-1.5 pb-8 text-lef text-start">
                  <p className="mb-2 text-base font-bold text-gray-900 dark:text-slate-300">3. Soumettez votre demande</p>
                  <p className="text-gray-600 text-base">Une fois votre compte activé, connectez-vous et accédez à la section pour trouver un médecin. Remplissez le formulaire de demande en indiquant votre localisation.</p>
                </div>
              </div>

              <div className="flex">
                <div className="mr-4 flex flex-col items-center">
                  <div>
                    <div
                      className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-[#1E6091] bg-[#1E6091]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                        className="h-6 w-6 text-white dark:text-slate-200">
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="pt-1.5 text-start">
                  <p className="mb-2 text-base font-bold">Prise en charge !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
          <div className="text-center">
            <h1 className="text-4xl sm:text-6xl font-bold text-[#1E6091] dark:text-gray-200">
              {strings.download_the_app_label}
            </h1>

            <p className="mt-3 text-gray-500">
              {strings.download_the_app_description_label}
            </p>

            <div className="mt-7 sm:mt-12 mx-auto max-w-xl relative">
              <div className="justify-center items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 mt-6">
                <a href="#"
                  className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                  <svg className="mr-3 w-7 h-7" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="apple"
                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path fill="currentColor"
                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z">
                    </path>
                  </svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">{strings.download_from_label}</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">{strings.app_store_label}</div>
                  </div>
                </a>
                <a href="#"
                  className="w-full sm:w-auto flex bg-gray-800 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                  <svg className="mr-3 w-7 h-7" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path fill="#32BBFF" d="M382.369,175.623C322.891,142.356,227.427,88.937,79.355,6.028 C69.372-0.565,57.886-1.429,47.962,1.93l254.05,254.05L382.369,175.623z"></path> <path fill="#32BBFF" d="M47.962,1.93c-1.86,0.63-3.67,1.39-5.401,2.308C31.602,10.166,23.549,21.573,23.549,36v439.96 c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314L302.012,255.98L47.962,1.93z"></path> <path fill="#32BBFF" d="M302.012,255.98L47.956,510.035c9.927,3.384,21.413,2.586,31.399-4.103 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L302.012,255.98z"></path> </g> <path fill="#2C9FD9" d="M23.549,255.98v219.98c0,14.427,8.052,25.834,19.012,31.761c1.728,0.917,3.537,1.68,5.395,2.314 L302.012,255.98H23.549z"></path> <path fill="#29CC5E" d="M79.355,6.028C67.5-1.8,53.52-1.577,42.561,4.239l255.595,255.596l84.212-84.212 C322.891,142.356,227.427,88.937,79.355,6.028z"></path> <path fill="#D93F21" d="M298.158,252.126L42.561,507.721c10.96,5.815,24.939,6.151,36.794-1.789 c143.598-80.41,237.986-133.196,298.152-166.746c1.675-0.941,3.316-1.861,4.938-2.772L298.158,252.126z"></path> <path fill="#FFD500" d="M488.45,255.98c0-12.19-6.151-24.492-18.342-31.314c0,0-22.799-12.721-92.682-51.809l-83.123,83.123 l83.204,83.205c69.116-38.807,92.6-51.892,92.6-51.892C482.299,280.472,488.45,268.17,488.45,255.98z"></path> <path fill="#FFAA00" d="M470.108,287.294c12.191-6.822,18.342-19.124,18.342-31.314H294.303l83.204,83.205 C446.624,300.379,470.108,287.294,470.108,287.294z"></path> </g></svg>
                  <div className="text-left">
                    <div className="mb-1 text-xs">{strings.available_on_label}</div>
                    <div className="-mt-1 font-sans text-sm font-semibold">{strings.google_play_label}</div>
                  </div>
                </a>
              </div>

              <div className="hidden md:block absolute top-0 end-0 -translate-y-12 translate-x-2">
                <svg className="w-16 h-auto text-[#1E6091]" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                  <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                  <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                </svg>
              </div>

              <div className="hidden md:block absolute bottom-0 start-0 translate-y-10 -translate-x-24">
                <svg className="w-40 h-auto text-[#1E6091]" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" strokeWidth="7" strokeLinecap="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
