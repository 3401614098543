import React, { useState, useEffect } from 'react';
import strings from '../../assets/strings';
import { useNavigate, useParams } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
import Modal from '../layouts/Modal';
// Utils imports
import { getTheRightRequestType } from './../../utils/AppointementRequestDecode';
import { getTheClosestAvailableDates, dateformatter, convertToISO8601Date, getFormalDateWithDayAsNumberShortcutMonthAndYear, calculateAge } from './../../utils/IAHelperForDate';
// Icons imports
import appLogo from './../../images/app_logo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import AppointementViewModel from '../appointement/AppointementViewModel';
import ApiService from '../../services/ApiService';
import { DocRequestData } from '../../types/docRequestModel';
import { AppointementResponse } from '../../types/AppointementResponse';
import { ModalTypeEnum } from '../../types/ModalTypeEnum';
import ErrorView from '../layouts/ErrorView';
import AcceptAppointementView from '../layouts/AcceptAppointementView';
import AppointmentRepository from '../../Domain/Appointments/AppointmentRepository';

export const Assignment = () => {
    const apiUrl = "https://api.doctraitant.fr/api";
    const apiService = new ApiService(apiUrl);
    // ViewModels
    const appointementViewModel = new AppointementViewModel(apiService);

    const appointmentRepository = new AppointmentRepository(localStorage.getItem("userId") ?? "", apiService);

    const navigate = useNavigate();

    let { id, uuidQuery } = useParams();

    function handleNavigationToDashboard() {
        // Handle navigation to signin from recover page
        // Then trigger navigation.
        navigate("/mydashboard");
    }

    const [loading, setLoadingState] = useState<boolean>(true);
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [patientAppointement, setPatientAppointement] = useState<DocRequestData | undefined>(undefined);
    const [toggle, setToggle] = useState(true);
    const [updateModal, setUpdateModal] = useState<boolean>(false)
    const [schedulingForm, setSchedulingForm] = useState<number>(0)
    const [actionModalOpened, setActionModalOpened] = useState(false);
    const [datesSuggestions, setDatesSuggestions] = useState<Date[]>([]);
    const [dateTimeValue, setDateTimeValue] = useState<string>('');
    const [modalType, setModalType] = useState<ModalTypeEnum>(ModalTypeEnum.Nothing);
    const [emailValue, setEmailValue] = useState<string>(localStorage.getItem("email") ?? "N/A");
    const [phoneValue, setPhoneValue] = useState<string>(localStorage.getItem("phone") ?? "N/A");
    const [adresseValue, setAdresseValue] = useState<string>(localStorage.getItem("address") ?? "N/A");
    const [cityValue, setCityValue] = useState<string>(localStorage.getItem("city") ?? "N/A");
    const [communeValue, setCommuneValue] = useState<string>(localStorage.getItem("township") ?? "N/A");
    const [zipcodeValue, setZipcodeValue] = useState<string>(localStorage.getItem("zipcode") ?? "N/A");
    const [isOpen, setIsOpen] = useState(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValue(event.target.value);
    };

    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneValue(event.target.value);
    };

    const handleDateTimeChange = (value: string) => {
        setDateTimeValue(value);
    };
    const handleCommuneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommuneValue(event.target.value);
    };

    const handleZipcodeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipcodeValue(event.target.value);
    };

    const handleCityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCityValue(event.target.value);
    };

    const handleAdresseInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdresseValue(event.target.value);
    };

    const [logoutModal, setLogoutModal] = useState(false)
    const { user, logout } = AuthData();

    //Load the appointement
    const loadAppointement = async () => {
        try {
            const response: AppointementResponse = await appointementViewModel.loadAppointement(id, localStorage.getItem("token"));
            setPatientAppointement(response.data);
            if (response.data?.requestState == "Acceptée") {
                handleNavigationToDashboard();
            } else {
                setLoadingState(false);
            }
        } catch (error) {
            console.log("error when loading data");
            console.log(error);
            handleNavigationToDashboard();
        }
    };

    const doLogout = async () => {
        try {
            await logout()
            localStorage.clear()
            navigate("/signin")
        } catch {
            console.log("logout from account")
        }
    }

    const toggleBoolean = () => {
        setToggle(!toggle);
    };

    const closeSchedulingModal = () => {
        setActionModalOpened(false);
        setSchedulingForm(0);
    }

    const modalLogoutStateChanger = () => {
        setLogoutModal(true);
    };

    const schedulePreviousForm = () => {
        setSchedulingForm(prevSchedulingForm => prevSchedulingForm - 1);
    }

    const [activeTab, setActiveTab] = useState<string>('tab-1');

    const handleTabClick = (tabId: string) => {
        setActiveTab(tabId);
    };

    const handleAppointementScheduling = () => {
        getTheClosestAvailableDates()
            .then(function (response) { return response; })
            .then(function (data) {
                const items = data;
                setDatesSuggestions(items ?? [])
            });
        setModalType(ModalTypeEnum.Accept);
        setActionModalOpened(true);
    };

    const handleDataShowValidation = () => {
        loadAppointement();
    };

    const scheduleAppointement = async () => {
        const incrementForm = () => {
            setSchedulingForm(prevSchedulingForm => prevSchedulingForm + 1);
        };

        if (schedulingForm < 2) {
            incrementForm();
            return;
        }

        if (schedulingForm === 2) {
            setDataLoading(true);
            try {
                const response = await appointmentRepository.updateAppointment(patientAppointement?._id ?? "", {
                    meetingDateTime: dateTimeValue,
                    requestState: "Acceptée",
                    meeting: {
                        doctorID: localStorage.getItem("userId"),
                        doctorUUID: localStorage.getItem("userUUID"),
                        doctorFirstname: localStorage.getItem("firstname"),
                        doctorLastname: localStorage.getItem("lastname"),
                        doctorAddress: adresseValue,
                        doctorAddressCity: cityValue,
                        doctorAddressZipcode: zipcodeValue,
                        doctorEmail: emailValue,
                        doctorPhone: phoneValue,
                        meetingDateTime: dateTimeValue,
                    },
                })
                console.log('Mise à jour de la demande réussie');
                setSchedulingForm(3);
            } catch (error) {
                setModalType(ModalTypeEnum.Error);
                setDataLoading(false);
                setActionModalOpened(true);
                console.error('Erreur lors de la mise à jour de la demande :', error);
            }
        }
    };

    const selectSuggestedDate = (selectedItem: Date) => {
        setDateTimeValue(convertToISO8601Date(selectedItem))
    }

    useEffect(() => {
        console.log("Load assignment view...");
        handleDataShowValidation();
    }, []);

    return (
        <div className="bg-slate-50 h-screen">
            <main id="content" className="bg-slate-50">
                <nav className="sticky -top-px bg-white text-sm flex items-center justify-between p-3 ring-1 ring-gray-900 ring-opacity-5 border-t shadow-sm shadow-gray-100" aria-label="Jump links">
                    <div className="flex items-center">
                        <img
                            src={appLogo}
                            alt="Logo de l'application"
                            className="h-10"
                        />
                    </div>

                    <div className="flex items-center justify-end">
                        <div onClick={modalLogoutStateChanger} className="block sm:hidden cursor-pointer">
                            <svg viewBox="0 0 24 24" fill="none" className="w-7 h-7 mr-2" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Iconly/Curved/Logout"> <g id="Logout"> <path id="Stroke 1" d="M21.791 12.1208H9.75" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 3" d="M18.8643 9.20483L21.7923 12.1208L18.8643 15.0368" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path id="Stroke 4" d="M16.3597 7.63C16.0297 4.05 14.6897 2.75 9.35974 2.75C2.25874 2.75 2.25874 5.06 2.25874 12C2.25874 18.94 2.25874 21.25 9.35974 21.25C14.6897 21.25 16.0297 19.95 16.3597 16.37" stroke="#FC5C65" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g> </g></svg>
                        </div>
                        <button
                            type="button"
                            className="py-1 px-2 inline-flex items-center gap-x-2 mr-2 text-sm font-semibold rounded-full border border-transparent bg-red-100 text-red-800 disabled:opacity-50 disabled:pointer-events-none hidden sm:block"
                            onClick={modalLogoutStateChanger}
                        >
                            <p className="font-regular flex items-center text-sm text-red-500">
                                Déconnexion
                            </p>
                        </button>
                        <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400" aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <AccountCircleIcon htmlColor="#22a6b3" fontSize="large" />
                        </button>
                    </div>
                </nav>

                {/* Skeleton UI for data loading  */}
                {
                    loading ? (
                        <div className="w-full mx-auto min-h-[15rem]">

                            <div className="rounded p-5 mt-2 mx-auto">
                                <div className="w-full animate-pulse flex space-x-4">
                                    <div className="flex-1 space-y-4 py-1">
                                        <div className="h-6 bg-slate-300/80 rounded"></div>
                                        <div className="space-y-3">
                                            <div className="h-5 bg-slate-300/80 rounded"></div>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="h-5 bg-slate-300/80 rounded col-span-2"></div>
                                                    <div className="h-5 bg-slate-300/80 rounded col-span-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="rounded pl-5 pr-5 mx-auto">
                                <div className="w-full animate-pulse flex space-x-4">
                                    <div className="flex-1 space-y-4 py-1">
                                        <div className="h-44 bg-slate-300/80 rounded"></div>
                                        <div className="space-y-3">
                                            <div className="h-14 bg-slate-300/80 rounded"></div>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="h-14 bg-slate-300/80 rounded col-span-2"></div>
                                                <div className="grid grid-cols-3 gap-4">
                                                    <div className="h-14 bg-slate-300/80 rounded col-span-2"></div>
                                                    <div className="h-14 bg-slate-300/80 rounded col-span-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full mx-auto min-h-[15rem]">
                            <div className="flex items-center gap-x-2 pt-6 pl-4 text-slate-400 font-medium">
                                <span className="cursor-pointer" onClick={handleNavigationToDashboard}>
                                    <div className="text-center text-start">
                                        <p className="inline-flex items-center">
                                            <svg className="flex-shrink-0 size-3 mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M11.2792 1.64001L5.63273 7.28646C5.43747 7.48172 5.43747 7.79831 5.63273 7.99357L11.2792 13.64" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                            <span>{strings.back_to_dashboard}</span>
                                        </p>
                                    </div>
                                </span>
                            </div>

                            <div className="flex items-center text-slate-400 mt-4 px-5">
                                <div className="w-full mx-auto ">
                                    <div className="flex flex-col">
                                        <div className="-m-1.5 overflow-x-auto">
                                            <div className="p-1.5 min-w-full inline-block align-middle">
                                                <div className="bg-white border border-gray-200 rounded-md shadow overflow-hidden">
                                                    <header className="flex justify-center z-50 w-full p-2 pb-2 pt-2 border-b border-gray-200">
                                                        <nav className="w-full flex items-center justify-between" aria-label="Global">
                                                            <div className="min-w-0">
                                                                <p className="p-2 font-semibold truncate block text-[#1E6091]">
                                                                    {`${patientAppointement?.lastname} ${patientAppointement?.firstname}`}
                                                                </p>
                                                            </div>

                                                            <div className="flex items-center justify-end p-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={handleAppointementScheduling}
                                                                    className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-100 text-blue-800 hover:bg-blue-200 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-blue-900 dark:text-blue-400">

                                                                    <CalendarMonthRoundedIcon fontSize="small" />
                                                                    <span className="hidden sm:inline">{strings.plan_meeting_label}</span>
                                                                </button>
                                                            </div>
                                                        </nav>
                                                    </header>

                                                    <div className="w-full flex flex-col justify-center mx-auto px-4 py-4">
                                                        <div className="space-y-4">
                                                            <div className="flex justify-start">
                                                                <div className="w-full">
                                                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nom et Prénom</h3>
                                                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                                        {`${patientAppointement?.lastname} ${patientAppointement?.firstname}`}
                                                                    </p>
                                                                </div>
                                                                <div className="w-full">
                                                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Âge</h3>
                                                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                                        {`${patientAppointement?.age}`}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="flex justify-start">
                                                                <div className="w-full">
                                                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nature de la demande</h3>
                                                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                                        {`${getTheRightRequestType(patientAppointement?.requestType ?? "")}`}

                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="flex justify-start">
                                                                <div className="w-full">
                                                                    <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Classification</h3>
                                                                    <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                                        {`${patientAppointement?.priority}`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="px-4 grid gap-3 md:flex justify-center items-center border-t border-gray-200 dark:border-gray-700">
                                                        <div>
                                                            <div className="inline-flex gap-x-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => setIsOpen(!isOpen)}
                                                                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium bg-white text-gray-800 disabled:opacity-50 disabled:pointer-events-none"
                                                                >
                                                                    {strings.more_details_label}
                                                                    {isOpen ? <KeyboardArrowUpRoundedIcon fontSize="small" /> : <KeyboardArrowDownRoundedIcon fontSize="small" />}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </main>

            <div
                className={`${isOpen ? '' : 'hidden'
                    }`}
            >
                <main id="content">
                    <nav className="sticky -top-px bg-slate-50 flex pt-5 pl-5 pr-5 pb-3 overflow-x-auto space-x-6 z-50" aria-label="Jump links">
                        <button onClick={() => handleTabClick('tab-1')} type="button" className={`font-semibold hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-1' ? 'active' : ''
                            } `} id="tabs-with-icons-item-1" data-hs-tab="#tabs-with-icons-1" aria-controls="tabs-with-icons-1" role="tab">
                            Vue d'ensemble
                        </button>
                        <button onClick={() => handleTabClick('tab-2')} type="button" className={`font-semibold hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-2' ? 'active' : ''
                            } `} id="tabs-with-icons-item-2" data-hs-tab="#tabs-with-icons-2" aria-controls="tabs-with-icons-2" role="tab">
                            Coordonnées
                        </button>
                        <button onClick={() => handleTabClick('tab-3')} type="button" className={`font-semibold hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-3' ? 'active' : ''
                            } `} id="tabs-with-icons-item-3" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                            Historique
                        </button>
                        <button onClick={() => handleTabClick('tab-4')} type="button" className={`font-semibold hs-tab-active:border-[#12CBC4] hs-tab-active:text-[#12CBC4] inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-[#12CBC4] focus:outline-none focus:text-[#12CBC4] disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:text-[#12CBC4] py-2 ${activeTab === 'tab-4' ? 'active' : ''
                            } `} id="tabs-with-icons-item-3" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                            Documents
                        </button>
                    </nav>

                    <div className="p-4 flex-1 bg-slate-50 overflow-y-auto">
                        <div className="space-y-4 overflow-y-auto">
                            {/* Tabs content */}
                            {activeTab === 'tab-1' && (
                                <div className="space-y-4">
                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Nom et Prénom</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.lastname} ${patientAppointement?.firstname}`}
                                            </p>
                                        </div>
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Date de naissance</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {patientAppointement && `${getFormalDateWithDayAsNumberShortcutMonthAndYear(patientAppointement.birthdate.toLocaleString()) ?? "N/A"} - ${calculateAge(convertToISO8601Date(patientAppointement.birthdate ?? "N/A"))} an(s)`}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Informations complémentaires</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.requestDescription}`}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {activeTab === 'tab-2' && (
                                <div className="space-y-4">
                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.adresse ?? "N/D"}`}
                                            </p>
                                        </div>
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Ville</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.city ?? "N/D"}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Commune</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.township ?? "N/D"}`}
                                            </p>
                                        </div>
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Code postal</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.zipcode ?? "N/D"}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Adresse e-mail</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.email}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex justify-start space-x-4">
                                        <div className="w-full bg-[#70a1ff]/5 rounded-md p-3">
                                            <h3 className="text-sm font-medium text-[#192a56]/90 dark:text-white text-left">Téléphone</h3>
                                            <p className="mt-1 text-gray-600 font-normal flex text-left text-xs">
                                                {`${patientAppointement?.phone}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab-3' && (
                                <div>
                                    <div>
                                        <div className="flex gap-x-3 pl-3">
                                            <div className="w-16 text-start">
                                                <span className="text-xs text-gray-500 dark:text-gray-400">{getFormalDateWithDayAsNumberShortcutMonthAndYear(patientAppointement?.createdDateTime.toLocaleString() ?? "N/D")}</span>
                                            </div>

                                            <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 end-2 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                                                <div className="relative z-10 w-7 h-7 flex justify-center items-center">
                                                    <div className="w-2 h-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                                </div>
                                            </div>

                                            <div className="grow pt-0.5 relative end-3">
                                                <h3 className="flex gap-x-1.5 font-semibold text-gray-600 dark:text-white text-sm">
                                                    <svg className="flex-shrink-0 w-4 h-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><line x1="16" x2="8" y1="13" y2="13" /><line x1="16" x2="8" y1="17" y2="17" /><line x1="10" x2="8" y1="9" y2="9" /></svg>
                                                    Création de la demande
                                                </h3>
                                                <p className="mt-5 font-normal italic text-xs text-gray-500 text-start">
                                                    Initialisation du processus par le demandeur
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'tab-4' && (
                                <div className="space-y-4">
                                    <div className="flex justify-start pl-1 pr-1">
                                        <div className="w-full">
                                            <h5 className="text-sm font-normal text-[#192a56]/90 dark:text-white text-left">
                                                Cette section regroupe tous les documents importés relatifs à la demande, qu'ils proviennent du demandeur ou des professionnels de santé.</h5>
                                            <p className="mt-5 pl-3 pr-3 text-gray-600 font-normal italic flex text-left text-xs">
                                                Aucun document n'a été importé pour cette demande.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>

            {/* Ask user for action with modal */}
            <Modal
                open={actionModalOpened}
                title=""
                onClose={() => setActionModalOpened(false)}>

                {modalType === ModalTypeEnum.Error &&
                    <ErrorView
                        onCloseClick={() => {
                            setModalType(ModalTypeEnum.Nothing)
                            setActionModalOpened(false)
                        }} />
                }
                {modalType === ModalTypeEnum.Accept &&
                    <AcceptAppointementView
                        onCloseClick={() => closeSchedulingModal()}
                        schedulePreviousForm={schedulePreviousForm}
                        scheduleAppointement={scheduleAppointement}
                        setDateTimeValue={handleDateTimeChange}
                        selectSuggestedDate={selectSuggestedDate}
                        handleAdresseInputChange={handleAdresseInputChange}
                        handleCityInputChange={handleCityInputChange}
                        handleCommuneInputChange={handleCommuneInputChange}
                        handleZipcodeInputChange={handleZipcodeInputChange}
                        handlePhoneInputChange={handlePhoneInputChange}
                        handleEmailInputChange={handleEmailInputChange}
                        dateTimeValue={dateTimeValue}
                        datesSuggestions={datesSuggestions}
                        isActionsDisabled={isActionsDisabled}
                        schedulingForm={schedulingForm}
                        phoneValue={phoneValue}
                        emailValue={emailValue}
                        cityValue={cityValue}
                        communeValue={communeValue}
                        zipcodeValue={zipcodeValue}
                        adresseValue={adresseValue}
                        dataLoading={dataLoading} />
                }
            </Modal>

            <Modal
                open={logoutModal}
                title=""
                onClose={() => setLogoutModal(false)} data-modal-backdrop="static">
                <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                            <LogoutRoundedIcon fontSize="medium" />
                            Déconnexion
                        </h3>
                        <button onClick={() => setLogoutModal(false)} type="button" className="flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-overlay="#hs-basic-modal">
                            <span className="sr-only">Close</span>
                            <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                        </button>
                    </div>

                    <div className="bg-white grid grid-cols-1 gap-4 lg:col-span-3 ">
                        <div className="p-2">
                            <div className="relative">
                                <div className="mx-auto mt-4 w-48">
                                    <p className="text-sm font-normal text-gray-700">
                                        Êtes-vous sûr(e) de vouloir vous déconnecter ?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 mb-4 flex justify-end gap-x-2">
                        <p onClick={() => setLogoutModal(false)} className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-lg border border-[#ff4757]/50 font-medium bg-white text-[#ff4757]/50 shadow-sm align-middle transition-all text-sm">
                            <CloseRoundedIcon fontSize="medium" />
                            Annuler
                        </p>
                        <p onClick={doLogout} className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-[#ff4757] border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none">
                            <LogoutRoundedIcon fontSize="medium" />
                            Confirmer
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}