import React, { useState, ChangeEvent, useEffect } from 'react';
import Modal from "./../layouts/Modal";
// Icons imports
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import noZipcodesEnabled from './../../images/no_zipcodes_enabled.png';

interface PostalCodeEntry {
    codePostal: string;
    codeCommune: string;
    nomCommune: string;
    libelleAcheminement: string;
}

interface SearchbarProps {
    selectedZipCode: string[] | undefined;
    onSelectedZipCodeChange: (filterdZipCode: string[] | undefined) => void;
}

const Searchbar: React.FC<SearchbarProps> = ({ selectedZipCode, onSelectedZipCodeChange }) => {
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);

    const jsonUrl = "https://unpkg.com/codes-postaux@4.0.0/codes-postaux.json";

    const [searchResults, setSearchResults] = useState<PostalCodeEntry[]>([]);

    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<string[] | undefined>(selectedZipCode);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filtredZipcodesModalOpen, setFiltredZipcodesModalOpen] = useState(false)
    const [isResetOrSelectZipcodeFilters, setIsResetOrSelectZipcodeFilters] = useState(false);

    const selectOrResetZipcodeFilters = () => {
        setSelectedItems(undefined)
    }

    const [isAutocompleteLoad, setIsAutocompleteLoad] = useState(false);

    const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setInputValue(inputValue);
        setIsDropdownOpen(true);
        setIsAutocompleteLoad(true)
        await searchPostalCodes(inputValue);
    };

    const handleItemClick = (item: string) => {
        if (selectedItems && selectedItems.length < 4 && !selectedItems.includes(item)) {
            setSelectedItems([...(selectedItems || []), item]);
            setInputValue('');
            setIsDropdownOpen(false);
        }
        if (!selectedItems) {
            setSelectedItems([...(selectedItems || []), item]);
            setInputValue('');
            setIsDropdownOpen(false);
        }
    };

    const handleBadgeClick = (item: string) => {
        if (selectedItems) {
            const updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
            if (updatedItems.length == 0) {
                setSelectedItems(undefined);
            } else {
                setSelectedItems(updatedItems);
            }
        }
    };

    const getCurrentZipcodeFiltersEnabled = () => {
        return selectedItems?.length ?? 0;
    };

    const handleDocumentClick = (event: MouseEvent) => {
        const target = event.target as Node;
        if (!document.getElementById('searchbar-container')?.contains(target)) {
            setIsDropdownOpen(false);
            setInputValue('');
        }
    };

    const searchPostalCodes = async (entry: string) => {
        try {
            const response = await fetch(jsonUrl);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data: PostalCodeEntry[] = await response.json();
            const filteredResults = data.filter((item) =>
                item.codePostal.startsWith(entry)
            );
            setSearchResults(filteredResults);
            setIsAutocompleteLoad(false)
        } catch (error) {
            setIsAutocompleteLoad(false)
            console.error('Error searching postal codes:', error);
        }
    };

    useEffect(() => {
        onSelectedZipCodeChange(selectedItems);

        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };

    }, [selectedItems]);

    return (
        <>
            <div className="mt-4 w-full">
                <div className="relative">
                    <input
                        type="text"
                        id="hs-leading-icon"
                        name="hs-leading-icon"
                        className="py-5 px-4 ps-11 block w-full shadow-sm rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none"
                        placeholder="Recherche par Code postal"
                        value={inputValue}
                        onChange={handleInputChange} />
                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
                        {
                            isAutocompleteLoad ? (
                                <div className="animate-spin inline-block w-5 h-5 border-[3px] border-current border-t-transparent text-gray-400 rounded-full" role="status" aria-label="loading">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="flex-shrink-0 h-4 w-4 text-gray-400 w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>
                            )
                        }
                    </div>
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-60">
                        <span onClick={() => setFiltredZipcodesModalOpen(true)} className="text-xs font-bold z-20 mr-2 rounded-xl border-4 border-[#12CBC4]/20 bg-[#12CBC4]/30 pl-1 pr-1">
                            <p className="text-start flex items-center text-xs md:text-sm sm:text-sm font-bold text-teal-700 rounded-md p-1 pb-1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                </svg>
                                <span className="ml-1">{getCurrentZipcodeFiltersEnabled()}</span>
                            </p>
                        </span>
                    </div>
                </div>
                {isDropdownOpen && inputValue && (
                    <ul className="autocomplete absolute mt-1 w-48 sm:w-64 bg-white rounded-lg border border-gray-300 shadow-md z-[40]">
                        {searchResults.slice(0, 3).map((item, index) => (
                            <li
                                key={index}
                                className="autocomplete-item py-2 px-2 cursor-pointer hover:bg-gray-100 transition duration-200"
                                onClick={() => handleItemClick(item.codePostal)}
                            >
                                <p className="text-start flex items-center text-sm gap-x-1 text-blue-950/70 font-normal cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                                    </svg>
                                    <span className="ml-1">{item.codePostal} {item.nomCommune}</span>
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
                {getCurrentZipcodeFiltersEnabled() > 0 && (
                    <div className="py-5">
                        <div className="relative flex items-center justify-between">
                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <div className="relative flex flex-row">
                                    <p onClick={() => setFiltredZipcodesModalOpen(true)} className="text-start flex items-center text-xs gap-x-1 font-normal cursor-pointer">
                                        <span className="text-sky-700">Afficher la sélection</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Modal
                open={filtredZipcodesModalOpen}
                title="Zipcode filters"
                onClose={() => setFiltredZipcodesModalOpen(false)} data-modal-backdrop="static">
                <div className="w-full shadow-lg rounded-xl bg-white p-2 pt-4 pl-4 pr-4">
                    <div className="flex justify-between items-center">
                        <h3 className="flex items-center gap-x-2 font-bold text-[#12CBC4]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                            </svg>
                            Codes postaux
                        </h3>
                        <button
                            onClick={() => {
                                if (!isActionsDisabled) {
                                    setFiltredZipcodesModalOpen(false);
                                }
                            }}
                            type="button"
                            className={`flex justify-center items-center w-7 h-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 disabled:opacity-50 disabled:pointer-events-none ml-4 ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                            disabled={isActionsDisabled}>
                            <span className="sr-only">Close</span>
                            <CancelRoundedIcon htmlColor="#a5b1c2" fontSize="medium" />
                        </button>
                    </div>
                    {selectedItems && selectedItems.length > 0 && (
                        <div className="relative flex items-center justify-between mt-5 pr-4">
                            <div className="absolute inset-y-0 right-0 flex items-center">
                                <div className="relative flex flex-row">
                                    <p
                                        onClick={() => {
                                            selectOrResetZipcodeFilters()
                                        }}
                                        className="flex items-center gap-x-2 text-xs font-normal text-slate-500 cursor-pointer">
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-slate-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                            Réinitialiser
                                        </>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="bg-white">
                        <div className="p-2">
                            <div className="relative">
                                <div className="relative sm:overflow-hidden">
                                    <div className="mt-8">
                                        {Array.isArray(selectedItems) && selectedItems.length > 0 ? (
                                            <div className="grid grid-cols-3 gap-3">
                                                {selectedItems.map((item) => (
                                                    <div key={item}>
                                                        <span className="py-2 px-3 inline-flex items-center gap-x-1 text-xs font-medium bg-blue-100 text-[#4834d4] rounded-full">
                                                            <svg onClick={() => handleBadgeClick(item)} className="w-5 h-5" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#4834d4" stroke="#4834d4" stroke-width="34.816"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#4834d4" d="M288 896h448q32 0 32 32t-32 32H288q-32 0-32-32t32-32z"></path><path fill="#4834d4" d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"></path><path fill="#4834d4" d="M384 384h256q32 0 32 32t-32 32H384q-32 0-32-32t32-32z"></path></g></svg>
                                                            {item}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            // No Zipcode in the selection 
                                            <div className="h-48 w-52">
                                                <span className="mb-1 inline-flex justify-center items-center">
                                                    <img
                                                        src={noZipcodesEnabled}
                                                        alt="Logo de l'application"
                                                        className="h-40 rounded-md opacity-80"
                                                    />
                                                </span>

                                                <h3 className="text-base font-light italic text-gray-400">
                                                    Aucun code postal sélectionné
                                                </h3>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 mb-4 flex justify-end items-center gap-x-2">
                        <button
                            onClick={() => setFiltredZipcodesModalOpen(false)}
                            type="button"
                            className={`flex items-center justify-center flex-1 bg-[#12CBC4] text-white p-2 w-full rounded-lg tracking-wide
                                    font-semibold font-display
                                    shadow-sm ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                            disabled={isActionsDisabled}>
                            {isActionsDisabled ? (
                                <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full mr-2" role="status" aria-label="loading">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <span>Fermer</span>
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Searchbar;