import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper"
import appLogo from './../../images/app_logo.png';
import Toast from './../layouts/Toast';
import strings from '../../assets/strings';
// Icons Imports
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import doctorsIcon from './../../images/doctors_icon.png';

export const Signin = () => {
    const navigate = useNavigate();
    const [isActionsDisabled, setIsActionsDisabled] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const { login } = AuthData();

    // Toast
    const [toastType, setToastType] = useState<string>('');
    const [toastMessage, setToastMessage] = useState<string>('');
    const [showToast, setShowToast] = useState(false);
    const handleShowToast = (type: 'success' | 'warning' | 'error', message: string) => {
        setIsActionsDisabled(false);
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
            setToastType('');
            setToastMessage('');
        }, 2000);
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevVisible) => !prevVisible);
    };

    function handleNavigationToRegistration() {
        // Handle navigation to registration from signin page
        // Then trigger navigation.
        navigate("/register");
    }

    function handleNavigationToPasswordRecovery() {
        // Handle navigation to password recovery from signin page
        // Then trigger navigation.
        navigate("/forgotPassword");
    }

    const handleSubmit = async (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        setIsActionsDisabled(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        try {
            await login(data.get('email'), data.get('password'))

            setTimeout(() => setShowToast(false), 2000);
            setIsActionsDisabled(false);
            navigate("/mydashboard")
        } catch (error) {
            console.log(error)
            handleShowToast("error", "Échec de l'authentification");
        }
    };

    useEffect(() => {
        document.title = strings.login_title_tab;
    }, []);

    return (
        <div>
            <div className="lg:flex">
                <div className="lg:w-1/2 xl:max-w-screen-sm">
                    <div className="py-12 bg-white flex justify-center lg:justify-start lg:px-12">
                        <div className="cursor-pointer flex items-center">
                            <div className="text-2xl tracking-wide ml-2 font-semibold">
                                <img
                                    src={appLogo}
                                    alt="Logo de l'application"
                                    className="h-16 rounded-md"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl">
                        <h2 className="text-center text-2xl text-[#12CBC4] font-display font-semibold lg:text-left xl:text-5xl
                xl:text-bold">{strings.authentification_label}</h2>
                        <div className="mt-12">
                            <form onSubmit={handleSubmit}>
                                <div className="mt-8">
                                    <div className="flex justify-between items-center">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">
                                            {strings.username_label}
                                        </div>
                                    </div>
                                    <input
                                        className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                                        placeholder={strings.username_placeholder_label}
                                        type="email"
                                        id="email"
                                        name="email"
                                    />
                                </div>
                                <div className="mt-8">
                                    <div className="flex justify-between items-center">
                                        <div className="text-sm font-bold text-gray-700 tracking-wide">
                                            {strings.password_label}
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <input
                                            className="w-full text-lg py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500 pr-10"
                                            placeholder={strings.password_placeholder_label}
                                            type={passwordVisible ? 'text' : 'password'}
                                            id="password"
                                            name="password"
                                        />
                                        <div
                                            className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#12CBC4]/70"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {passwordVisible ? <VisibilityOffRoundedIcon fontSize="small" /> : <VisibilityRoundedIcon fontSize="small" />}
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <a className="text-xs font-display font-semibold text-[#12CBC4]
                                    cursor-pointer pt-4" onClick={isActionsDisabled ? undefined : handleNavigationToPasswordRecovery}>
                                            {strings.forgot_password_label}
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <button
                                        type="submit"
                                        className={`flex items-center justify-center flex-1 bg-[#12CBC4]/90 text-gray-100 p-4 w-full rounded-lg tracking-wide
                                    font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-[#12CBC4]
                                    shadow-lg ${isActionsDisabled ? 'disabled:opacity-50 disabled:pointer-events-none' : ''}`}
                                        disabled={isActionsDisabled}>
                                        {isActionsDisabled ? (
                                            <div className="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-white rounded-full mr-2" role="status" aria-label="loading">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            <span>{strings.login_label}</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                            <div className="mt-12 text-sm font-display font-semibold text-gray-500 text-center">
                                {strings.not_account_yet_label} <a className={`cursor-pointer text-[#12CBC4] ${isActionsDisabled ? 'pointer-events-none opacity-50' : ''}`} onClick={isActionsDisabled ? undefined : handleNavigationToRegistration}>{strings.register_label}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex items-center justify-center bg-[#12CBC4]/20 flex-1 h-screen">
                    <div className="max-w-xs transform duration-200 hover:scale-110 cursor-pointer">
                        <img
                            src={doctorsIcon}
                            alt="Logo de l'application"
                            className="w-5/6 mx-auto"
                        />
                    </div>
                </div>
            </div>
            {showToast && (
                <Toast type={toastType} message={toastMessage} />
            )}
        </div>
    )
}